import React from 'react';

import Layout from '../components/Layout';
import { Seo } from '../components/Seo';
import { StaticImage } from 'gatsby-plugin-image';

const IndexPage = () => (
  <Layout fullMenu subPage>
    <Seo
      description=''
      keywords=''
      title='Rodinný a svatební fotograf'
    />
    <article id='main'>
      <header id='main-photo' className='sub-page'>
        <StaticImage
          src='../assets/images/pic05.jpg' alt='A kitten'
          placeholder='blurred'
          objectFit='cover'
          className='static-image'
          objectPosition='50% 0%'
        />
        <div className='header-text'>
          <h1>Ceník</h1>
          <p>
            Nemám ráda různé paušalizované fotobalíčky, nerozlišuji, <br />
            když fotím neposedné děti nebo zamilovaný pár.
            <br />
            Proto zde najdete pouze cenu za klasické focení a poté svatební
            nabídku.
          </p>
        </div>

      </header>
      <section className='wrapper style5 pricelist'>
        <div className='inner'>
          <section id='three' className='style3 special'>
            <div className='inner'>
              <ul className='features'>
                <li className=''>
                  <h3>2000 Kč</h3>
                  <p>
                    Portrét, rodinné foto, děti, těhotenské foto a psi (25 ks
                    fotografií)
                  </p>
                </li>
                <li className=''>
                  <h3>15 000 Kč</h3>
                  <p>Svatební foto (7 hod focení)<br />
                    každá další hodina + 1000 Kč
                  </p>
                </li>
              </ul>
            </div>
          </section>
        </div>
      </section>
    </article>
  </Layout>
);

export default IndexPage;
